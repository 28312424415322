import { useSelector } from "react-redux";
import { RootStateType } from "../store/store";
import { useCallback, useEffect, useMemo, useState } from "react";
import User from "../models/User";
import { useDispatch } from "react-redux";
import { Network, NetworkResponseType } from "./Network";
import { PURSEFIT_API_BASE_URL } from "../constants/constants";
import { setCustomerWalletBalance } from "../store/reducers/customer-reducer";

const Utils = () => {
	const { user } = useSelector((state: RootStateType) => state.Customer);
	const dispatch = useDispatch();
	const { GET } = Network();

	const refreshWalletBalance = useCallback(async () => {
		const res: NetworkResponseType = await GET(`${PURSEFIT_API_BASE_URL}/fetch-wallet-balance`);

		if (res.status === "success") {
			dispatch(setCustomerWalletBalance(res.data as { balance: number }));
		}
	}, []);

	const fetchColorAndText = useCallback((status: string, treatCompletedAsDelivered: boolean = false) => {
		if (status === "completed" && treatCompletedAsDelivered) {
			return { color: "green", text: "Success", spanColor: "#52c41a" };
		} else {
			switch (status) {
				case "delivered":
					return { color: "green", text: "Success", spanColor: "#52c41a" };
				case "failed":
					return { color: "red", text: "Failed", spanColor: "#f5222d" };
				case "api-failed":
					return { color: "red", text: "Failed", spanColor: "#f5222d" };
				case "initiated":
					return { color: "cyan", text: "Initiated", spanColor: "#13c2c2" };
				case "reversed":
					return { color: "purple", text: "Reversed", spanColor: "#722ed1" };
				default:
					return { color: "volcano", text: "Pending", spanColor: "#fa541c" };
			}
		}
	}, []);

	const ucwords = useCallback((words: string) => {
		let wordSplits = words.split(" ");
		let formattedWord = wordSplits.reduce((prev, current, index) => {
			return prev + " " + (current.trim().length > 0 ? current.trim().charAt(0).toUpperCase() + current.trim().substring(1) : "");
		}, "");
		return formattedWord;
	}, []);

	const name = useMemo(() => {
		var nameBuilder = "";
		if (user.first_name != null || user.last_name != null) {
			if (user.first_name != null) {
				nameBuilder += user.first_name;
			}

			if (user.last_name != null) {
				nameBuilder += " " + user.last_name;
			}
		} else {
			var emailArr = user.email.split("@");
			nameBuilder += emailArr[0];
		}
		return nameBuilder.trim();
	}, [user.first_name, user.last_name, user.email]);

	const extractName = useCallback((selected: User) => {
		var nameBuilder = "";
		if (selected.first_name != null || selected.last_name != null) {
			if (selected.first_name != null) {
				nameBuilder += selected.first_name;
			}

			if (selected.last_name != null) {
				nameBuilder += " " + selected.last_name;
			}
		}

		return nameBuilder.trim() === "" ? "Not Set" : nameBuilder.trim();
	}, []);

	const [windowSize, setWindowSize] = useState({
		windowWidth: window.innerWidth,
		windowHeight: window.innerHeight,
	});

	const constructQueryString = useCallback((obj: { [key: string]: any }) => {
		const queryStr =
			"?" +
			Object.keys(obj)
				.map((key) => {
					if (obj[key] !== null && obj[key] !== undefined) {
						return `${key}=${encodeURIComponent(obj[key])}`;
					}
					return "";
				})
				.join("&");

		return queryStr;
	}, []);

	const formatAmount = useCallback(
		(amount: number) =>
			parseFloat(amount.toString())
				.toFixed(2)
				.replace(/\d(?=(\d{3})+\.)/g, "$&,"),
		[]
	);

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				windowWidth: window.innerWidth,
				windowHeight: window.innerHeight,
			});
		}
		window.addEventListener("resize", handleResize);
		handleResize();

		return () => window.removeEventListener("resize", handleResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		name,
		windowSize,
		extractName,
		constructQueryString,
		ucwords,
		fetchColorAndText,
		refreshWalletBalance,
		formatAmount,
	};
};

export default Utils;
