import React, { ReactNode } from "react";
import * as headings from "./style";

type HeadingPropType = {
	tag: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	children?: string | ReactNode;
	className?: string;
	id?: string;
};

function Heading(props: HeadingPropType = { tag: "h1" }) {
	const { tag, children, className, id } = props;
	const StyledHeading = tag ? headings[tag.toUpperCase() as keyof typeof headings] : headings.H1;

	return (
		<StyledHeading className={className} id={id}>
			{children}
		</StyledHeading>
	);
}

export default Heading;
