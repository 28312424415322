import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "../store/store";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import VerticalLayout from "../layout/vertical-layout";
import { Spin } from "antd";
import PrivateRoutes from "../routes/private-routes";
import { PageHeader } from "../components/page-headers/page-headers";
import { Main } from "../styles/main-style";

const AuthenticationMiddleware = () => {
	const { isAuthenticated } = useSelector((state: RootStateType) => state.Customer);
	const location = useLocation();
	const navigate = useNavigate();

	// if (!isAuthenticated) {
	// 	return <Navigate to={"/"} replace />;
	// }

	return (
		<React.Fragment>
			<VerticalLayout>
				<Suspense
					fallback={
						<div className="spin">
							<Spin />
						</div>
					}
				>
					<Routes>
						{PrivateRoutes.map((route, index) => {
							let FComponent = route.component;
							return (
								<Route
									key={index}
									path={route.path}
									element={
										<React.Fragment>
											<Helmet>
												<title>{route.title}</title>
											</Helmet>
											<FComponent />
										</React.Fragment>
									}
								></Route>
							);
						})}
					</Routes>
				</Suspense>
			</VerticalLayout>
		</React.Fragment>
	);
};

export default AuthenticationMiddleware;
