import React, { Component, useCallback, useEffect, useState } from "react";
import Breadcrumb from "../../models/Breadcrumb";
import Alert from "../../components/Alert";
import Utils from "../../shared/utils";
import { connect, useDispatch, useSelector } from "react-redux";
import { PageHeader } from "../../components/page-headers/page-headers";
import { RootStateType } from "../../store/store";
import propTypes from "prop-types";
import { FooterStyle, LayoutContainer, SmallScreenAuthInfo, TopMenuSearch } from "./styled";
import { Button, Col, Layout, Row } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import AuthInfo from "../../components/utilities/auth-info/info";
import Search from "../../components/utilities/auth-info/Search";
import { UilEllipsisV, UilHeart } from "@iconscout/react-unicons";
import { ThemeProvider } from "styled-components";
import { Scrollbars } from "@pezhmanparsaee/react-custom-scrollbars";
import MenueItems from "./menu-items/MenueItems";
import TopMenu from "./top-menu-seearch/TopMenu";
import { format } from "date-fns";

const { theme } = require("../../config/theme/themeVariables");

const { Header, Sider, Content } = Layout;

type VerticalLayoutPropType = {
	children: React.ReactNode;
	// breadCrumbs: Breadcrumb[];
	// title: string;
};

const VerticalLayout = ({ children }: VerticalLayoutPropType) => {
	const [layoutState, setLayoutState] = useState({ collapsed: true, hide: true } as { collapsed: boolean; hide: boolean });

	const location = useLocation();

	const updateDimensions = () => {
		setLayoutState((state) => ({ ...state, collapsed: window.innerWidth <= 1200 && true }));
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);

		return () => {
			window.removeEventListener("resize", updateDimensions);
		};
	}, [window.innerWidth]);

	useEffect(() => {
		if (window.innerWidth >= 1200) {
			setLayoutState((state) => ({ ...state, collapsed: false }));
		}
	}, []);

	const { collapsed, hide } = layoutState;

	const { layoutMode, rtl, topMenu, name, logo } = useSelector((state: RootStateType) => ({
		layoutMode: state.Layout.mode,
		rtl: state.Layout.rtlData,
		topMenu: state.Layout.topMenu,
		name: state.App.name,
		logo: state.App.logo,
	}));

	const left = !rtl ? "left" : "right";
	const toggleCollapsed = () => {
		setLayoutState((state) => {
			return { ...state, collapsed: !collapsed };
		});
	};

	const toggleCollapsedForMenu = () => {
		if (window.innerWidth <= 990) {
			setLayoutState((state) => ({ ...state, collapsed: true }));
		}
	};

	const toggleCollapsedMobile = () => {
		if (window.innerWidth <= 990) {
			setLayoutState((state) => ({ ...state, collapsed: !collapsed }));
		}
	};

	const onShowHide = () => {
		setLayoutState((state) => ({ ...state, hide: !hide }));
	};

	const SideBarStyle = {
		margin: "63px 0 0 0",
		padding: `${!rtl ? "20px 20px 55px 0" : "20px 0 55px 20px"}`,
		overflowY: "auto",
		height: "100vh",
		position: "fixed",
		[left]: 0,
		zIndex: 988,
	};

	const renderView = ({ style }: any) => {
		const customStyle = {
			marginRight: "auto",
			[rtl ? "marginLeft" : "marginRight"]: "-17px",
		};
		return <div style={{ ...style, ...customStyle }} />;
	};

	const renderThumbVertical = ({ style }: any) => {
		// const { ChangeLayoutMode } = this.props as any;
		const thumbStyle = {
			borderRadius: 6,
			// backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
			backgroundColor: "#ffffff16",
			[left]: "2px",
		};
		return <div style={{ ...style, ...thumbStyle }} />;
	};

	const renderThumbHorizontal = ({ style }: any) => {
		// const { ChangeLayoutMode } = this.props as any;
		const thumbStyle = {
			borderRadius: 6,
			// backgroundColor: ChangeLayoutMode ? "#ffffff16" : "#F1F2F6",
			backgroundColor: "#ffffff16",
		};
		return <div style={{ ...style, ...thumbStyle }} />;
	};

	useEffect(() => {
		setLayoutState((state) => ({ ...state, hide: true }));
	}, [location.pathname]);

	return (
		<React.Fragment>
			<Alert />
			<LayoutContainer>
				<Layout className="layout">
					<Header
						style={{
							position: "fixed",
							width: "100%",
							top: 0,
							[!rtl ? "left" : "right"]: 0,
						}}
					>
						<div className="ninjadash-header-content d-flex">
							<div className="ninjadash-header-content__left">
								<div className="navbar-brand align-cener-v">
									<Link className={topMenu && window.innerWidth > 991 ? "ninjadash-logo top-menu" : "ninjadash-logo"} to="/">
										<img style={{ maxHeight: "50px" }} src={`/${logo ?? undefined}`} alt="" />
									</Link>
									{window.innerWidth <= 991 ? (
										<Button type="link" onClick={toggleCollapsed}>
											<img src={require(`../../static/img/icon/${collapsed ? "left-bar.svg" : "left-bar.svg"}`)} alt="menu" />
										</Button>
									) : null}
								</div>
							</div>
							<div className="ninjadash-header-content__right d-flex">
								<div className="ninjadash-navbar-menu d-flex align-center-v">{topMenu && window.innerWidth > 991 ? <TopMenu /> : ""}</div>
								<div className="ninjadash-nav-actions">
									{topMenu && window.innerWidth > 991 ? (
										<TopMenuSearch>
											<div className="top-right-wrap d-flex">
												<AuthInfo />
											</div>
										</TopMenuSearch>
									) : (
										<AuthInfo />
									)}
								</div>
							</div>
							<div className="ninjadash-header-content__mobile">
								<div className="ninjadash-mobile-action">
									{/* <div className="btn-search">
										<Search />
									</div> */}

									<Link className="btn-auth" onClick={onShowHide} to="#">
										<UilEllipsisV />
									</Link>
								</div>
							</div>
						</div>
					</Header>
					<div className="ninjadash-header-more">
						<Row>
							<Col md={0} sm={24} xs={24}>
								<div className="ninjadash-header-more-inner">
									<SmallScreenAuthInfo hide={hide}>
										<AuthInfo />
									</SmallScreenAuthInfo>
								</div>
							</Col>
						</Row>
					</div>
					<Layout>
						{!topMenu || window.innerWidth <= 991 ? (
							<ThemeProvider theme={theme}>
								<Sider width={280} style={SideBarStyle as any} collapsed={collapsed} theme={layoutMode === "lightMode" ? "light" : "dark"}>
									<Scrollbars
										className="custom-scrollbar"
										autoHide
										autoHideTimeout={500}
										autoHideDuration={200}
										renderThumbHorizontal={renderThumbHorizontal}
										renderThumbVertical={renderThumbVertical}
										renderView={renderView}
										renderTrackVertical={(props: any) => <div {...props} className="ninjadash-track-vertical" />}
									>
										<MenueItems toggleCollapsed={toggleCollapsedForMenu} />
									</Scrollbars>
								</Sider>
							</ThemeProvider>
						) : null}
						<Layout className="atbd-main-layout">
							<Content style={{ marginBottom: "50px" }}>{children}</Content>
							<FooterStyle className="admin-footer">
								<Row>
									<Col md={12} xs={24}>
										<span className="admin-footer__copyright">
											© {format(new Date(), "Y")}
											<Link to="#">{name}</Link>
										</span>
									</Col>
									<Col md={12} xs={24}>
										<div className="admin-footer__links">
											<NavLink to="#">About</NavLink>
											<NavLink to="#">Team</NavLink>
											<NavLink to="/support">Contact</NavLink>
										</div>
									</Col>
								</Row>
							</FooterStyle>
						</Layout>
					</Layout>
				</Layout>
				{window.innerWidth <= 991 ? <span className={collapsed ? "ninjadash-shade" : "ninjadash-shade show"} onClick={toggleCollapsed} /> : ""}
			</LayoutContainer>
		</React.Fragment>
	);
	// return (
	// 	<React.Fragment>
	// 		<Alert />
	// 		<PageHeader className="ninjadash-page-header-main" title="Dashboard" routes={props.breadCrumbs}></PageHeader>

	// 		<Navigation />
	// 		<NavBar />
	// 		<div className="pcoded-main-container" onClick={() => mobileOutClickHandler}>
	// 			<div className={mainClass.join(" ")}>
	// 				<div className="pcoded-content">
	// 					<div className="pcoded-inner-content">
	// 						<PageHeader breadCrumbs={props.breadCrumbs} pageTitle={props.title} />
	// 						<div className="main-body">
	// 							<div className="page-wrapper">{props.children}</div>
	// 						</div>
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</React.Fragment>
	// );
};

export default VerticalLayout;
