import React from "react";
import Breadcrumb from "../models/Breadcrumb";

type PrivateRouteType = {
	path: string;
	component: React.FC;
	title: string;
};

const Page404 = React.lazy(() => import("../pages/404"));
const Page500 = React.lazy(() => import("../pages/500"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const Home = React.lazy(() => import("../pages/Home"));
const CategoryPage = React.lazy(() => import("../pages/Category"));
const BuyPage = React.lazy(() => import("../pages/BuyProduct"));
const ConfirmTransactionPage = React.lazy(() => import("../pages/ConfirmTransaction"));
const Register = React.lazy(() => import("../pages/Register"));
const Login = React.lazy(() => import("../pages/Login"));
const FundWallet = React.lazy(() => import("../pages/FundWallet"));
const TransactionPage = React.lazy(() => import("../pages/Transaction"));
const TransactionListPage = React.lazy(() => import("../pages/TransactionList"));
const ReportPage = React.lazy(() => import("../pages/Reports"));
const WalletTransactionListPage = React.lazy(() => import("../pages/WalletTransactionList"));
const Account = React.lazy(() => import("../pages/Account"));
const Support = React.lazy(() => import("../pages/Support"));
const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));
const ConfirmResetPassword = React.lazy(() => import("../pages/ConfirmResetPassword"));

const PrivateRoutes: PrivateRouteType[] = [
	{
		path: "/cat/:identifier",
		component: CategoryPage,
		title: "",
	},
	{
		path: "/buy/:identifier",
		component: BuyPage,
		title: "",
	},
	{
		path: "/confirm-transaction/:reference",
		component: ConfirmTransactionPage,
		title: "",
	},
	{
		path: "/transaction/:reference",
		component: TransactionPage,
		title: "",
	},
	{
		path: "/register",
		component: Register,
		title: "Sign Up",
	},
	{
		path: "/login",
		component: Login,
		title: "Sign In",
	},
	{
		path: "/reset-password",
		component: ResetPassword,
		title: "Reset Password",
	},
	{
		path: "/confirm-reset-password/:email/:token",
		component: ConfirmResetPassword,
		title: "Reset Password",
	},
	{
		path: "/fund-wallet",
		component: FundWallet,
		title: "Fund Your Wallet",
	},
	{
		path: "/transactions",
		component: TransactionListPage,
		title: "My Transactions",
	},
	{
		path: "/reports",
		component: ReportPage,
		title: "My Reports",
	},
	{
		path: "/wallet-transactions",
		component: WalletTransactionListPage,
		title: "My Wallet Transactions",
	},
	{
		path: "/account/*",
		component: Account,
		title: "My Account",
	},
	{
		path: "/dashboard",
		component: Dashboard,
		title: "Dashboard",
	},
	{
		path: "/support",
		component: Support,
		title: "Support",
	},
	{
		path: "/500",
		component: Page500,
		title: "Internal Server Error",
	},
	{
		path: "/",
		component: Home,
		title: "",
	},
	{
		path: "/*",
		component: Page404,
		title: "Not Found",
	},
];

export default PrivateRoutes;
