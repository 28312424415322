import { UilUser, UilUsersAlt, UilSignout, UilSetting, UilDollarSign, UilBell, UilAngleDown, UilWallet } from "@iconscout/react-unicons";
import { Avatar, Button, Space } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import Message from "./Message";
import Notification from "./Notification";
import Search from "./Search";
import Settings from "./settings";

import { Dropdown } from "../../dropdown/dropdown";
import Heading from "../../heading/heading";
import { Popover } from "../../popup/popup";
import Utils from "../../../shared/utils";
// import { Button } from "../../buttons/buttons";
import Styled from "styled-components";
import { useSelector } from "react-redux";
import { RootStateType } from "../../../store/store";
import Userpng from "../../../assets/images/profile-user.png";
import { Network, NetworkResponseType } from "../../../shared/Network";
import { PURSEFIT_API_BASE_URL } from "../../../constants/constants";
import { clearCustomer } from "../../../store/reducers/customer-reducer";

const WalletButton = Styled(Button)`
	font-size: 14px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: "4px";
	padding: 0px 10px;
	height: ${({ theme }) => theme[`btn-height-extra-small`]};
	font-weight: 600;
	box-shadow: 0 0;
	i,
	svg,
	img {
		width: 14px;
		height: 14px;
		+ span {
			${({ theme }) => (theme.rtl ? "margin-right" : "margin-left")}: 6px;
		}
	}
	background: transparent;
	border: ${({ type, theme }) => `1px solid ${theme[`${type}-color`]}`};
	color: ${({ type, theme }) => theme[`${type}-color`]};
	&:hover,
	&:focus {
		background: ${({ type, theme }) => theme[`${type}-color`]};
		border: ${({ type, theme }) => `1px solid ${theme[`${type}-hover`]}`};
		color: '#ffffff';
		svg{
			transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
			color: '#ffffff';
		}
	}
`;

const StyledLink = Styled(Link)`
	color: inherit;
	&:hover{
		color: ${({ type, theme }) => theme[`${type}-color`]}
	}
`;

const AuthInfo = React.memo(() => {
	const dispatch = useDispatch();
	const { isAuthenticated, walletBalance } = useSelector((state: RootStateType) => state.Customer);

	const { name } = Utils();
	const { GET } = Network();
	const navigate = useNavigate();

	const SignOut = useCallback(async () => {
		let res: NetworkResponseType = await GET(`${PURSEFIT_API_BASE_URL}/logout`);

		if (res.status === "success") {
			dispatch(clearCustomer());
			navigate("/");
		}
	}, []);

	const NGN = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "NGN",
	});

	const userContent = (
		<UserDropDwon>
			<div className="user-dropdwon">
				<figure className="user-dropdwon__info">
					<img src={require("../../../static/img/avatar/chat-auth.png")} alt="" />
					<figcaption>
						<Heading tag="h5">{name}</Heading>
						<p>Customer</p>
					</figcaption>
				</figure>
				<ul className="user-dropdwon__links">
					<li>
						<Link to="/account">
							<UilUser /> Profile
						</Link>
					</li>
				</ul>
				<Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
					<UilSignout /> Sign Out
				</Link>
			</div>
		</UserDropDwon>
	);

	return (
		<InfoWraper>
			{!isAuthenticated && (
				<React.Fragment>
					<div className="ninjadash-nav-actions__item">
						<StyledLink to="/login" type="primary" className="ninjadash-nav-action-link">
							Login
						</StyledLink>
					</div>
					<div className="ninjadash-nav-actions__item">
						<StyledLink to="/register" type="primary" className="ninjadash-nav-action-link">
							Register
						</StyledLink>
					</div>
				</React.Fragment>
			)}
			{isAuthenticated && (
				<React.Fragment>
					<div className="">
						<WalletButton type="primary">
							<UilWallet /> {NGN.format(walletBalance)}
						</WalletButton>
					</div>
					<div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
						<Popover placement="bottomRight" content={userContent} action="click">
							<Link to="#" className="ninjadash-nav-action-link">
								<Avatar src={Userpng} />
								<span className="ninjadash-nav-actions__author--name">{name}</span>
								<UilAngleDown />
							</Link>
						</Popover>
					</div>
				</React.Fragment>
			)}
		</InfoWraper>
	);
});

export default AuthInfo;
