import PropTypes from "prop-types";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { Content, PopoverStyle, Title } from "./style";
import "./style.css";
import { TooltipPlacement } from "antd/lib/tooltip";

type PopoverPropType = {
	placement: TooltipPlacement | undefined;
	title?: string;
	action: string;
	content: ReactNode;
	children?: ReactNode;
};

function Popover(
	props: PopoverPropType = {
		placement: "bottom",
		action: "hover",
		content: (
			<>
				<Link to="#">
					<i className="uil uil-check"></i>
					<span>Btn Dropdown one</span>
				</Link>
				<Link to="#">
					<i className="uil uil-check"></i>
					<span>Btn Dropdown two</span>
				</Link>
				<Link to="#">
					<i className="uil uil-check"></i>
					<span>Btn Dropdown three</span>
				</Link>
			</>
		),
	}
) {
	const { content, placement, title, action, children } = props;
	const content1 = <Content>{content}</Content>;

	return (
		<PopoverStyle placement={placement} title={title && <Title>{title}</Title>} content={content1} trigger={action}>
			{children}
		</PopoverStyle>
	);
}

export { Popover };
