import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import App from "../../models/App";
import Breadcrumb from "../../models/Breadcrumb";
import Category from "../../models/Category";
import GeneralSetting from "../../models/GeneralSetting";

const AppReducerState: App & { menuList: Category[] } = {
	errors: [],
	messages: [],
	pageTitle: "",
	breadCrumbs: [],
	menuList: [],
	favicon: null,
	logo: null,
	name: null,
	url: null,
	support_email: null,
	support_phone: null,
	transactional_email: null,
	billboard_description: null,
};

const AppReducer = createSlice({
	name: "app-reducer",
	initialState: AppReducerState,
	reducers: {
		setErrors: (state, action: PayloadAction<string[]>) => {
			return { ...state, errors: action.payload, messages: [] };
		},

		setMessages: (state, action: PayloadAction<string[]>) => {
			return { ...state, messages: action.payload, errors: [] };
		},

		clearErrorsAndMessages: (state) => {
			return { ...state, errors: [], messages: [] };
		},

		setPageTitle: (state, action: PayloadAction<string>) => {
			return { ...state, pageTitle: action.payload };
		},

		setBreadCrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
			return { ...state, breadCrumbs: action.payload };
		},

		setMenuList: (state, action: PayloadAction<Category[]>) => {
			return { ...state, menuList: action.payload };
		},

		setPlatformSettings: (state, action: PayloadAction<GeneralSetting>) => {
			return {
				...state,
				favicon: action.payload.favicon,
				logo: action.payload.logo,
				name: action.payload.name,
				url: action.payload.url,
				support_email: action.payload.support_email,
				support_phone: action.payload.support_phone,
				transactional_email: action.payload.transactional_email,
				billboard_description: action.payload.billboard_description,
			};
		},
	},
});

export const { setErrors, setMessages, clearErrorsAndMessages, setPageTitle, setBreadCrumbs, setMenuList, setPlatformSettings } = AppReducer.actions;
export default AppReducer.reducer;
