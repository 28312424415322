import React, { Dispatch, ReactNode, SetStateAction } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navigate } from "react-router-dom";
import imgMaintenance from "../../assets/images/maintenance/maintance.png";
import { Main } from "../../styles/main-style";
import { ErrorWrapper } from "../../pages/404/style";
import Heading from "../heading/heading";
import { Button } from "../buttons/buttons";

interface Props {
	children: ReactNode;
	hasError: boolean;
	setHasError: Dispatch<SetStateAction<boolean>>;
}

interface State {
	hasError: boolean;
}

class InnerErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(_: Error) {
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any): void {
		// console.error("Error showcase:", error, errorInfo);
		// this.props.setHasError(true);
		this.props.setHasError(true);
		this.setState({ hasError: true });
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		// if (this.state.hasError) {
		// 	// document.body.className = "account-body accountbg";
		// }
		if (!this.props.hasError && prevProps.hasError) {
			this.setState({ hasError: false });
		}
	}

	render() {
		if (this.state.hasError) {
			return (
				<React.Fragment>
					<Main>
						<ErrorWrapper>
							<img src={require(`../../static/img/pages/support.svg`).default} alt="404" />
							<Heading className="error-text" tag="h3">
								500
							</Heading>
							<p>Sorry! System glitch.</p>
							<NavLink to="/admin">
								<Button size="default" type="primary" to="/">
									Return Home
								</Button>
							</NavLink>
						</ErrorWrapper>
					</Main>
				</React.Fragment>
			);
		}

		return this.props.children;
	}
}

export default InnerErrorBoundary;
