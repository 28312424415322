import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setErrors, setMessages } from "../store/reducers/app-reducer";
import { clearCustomer } from "../store/reducers/customer-reducer";

export type NetworkResponseType = {
	status: "success" | "failed" | "pending";
	data: Object | null;
};

export const Network = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const GET = async (url: string, ifErrorRedirect: boolean = false, ignoreError: boolean = false): Promise<NetworkResponseType> => {
		return axios
			.get(url)
			.then((response) => {
				return {
					status: "success",
					data: response.data.data,
				} as NetworkResponseType;
			})
			.catch((ex) => {
				const statusCode = ex.response.status;

				if (ignoreError) {
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				}

				if (statusCode === 401) {
					dispatch(clearCustomer());
					if (ifErrorRedirect) {
						navigate("/");
					}
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				}
				if (statusCode === 403) {
					if (ifErrorRedirect) {
						navigate("/403");
						return {
							status: "failed",
							data: null,
						} as NetworkResponseType;
					}
					dispatch(setErrors(["Unathorized request"]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				} else if (statusCode === 404) {
					if (ifErrorRedirect) {
						navigate("/404");
						return {
							status: "failed",
							data: null,
						} as NetworkResponseType;
					}
					dispatch(setErrors(["Resource does not exist"]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				} else if (statusCode === 400) {
					dispatch(setErrors(Array.isArray(ex.response.data.message) ? ex.response.data.message : [ex.response.data.message]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				} else if (statusCode === 422) {
					dispatch(setErrors(Array.isArray(ex.response.data.message) ? ex.response.data.message : [ex.response.data.message]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				} else {
					if (ifErrorRedirect) {
						navigate("/500");
						return {
							status: "failed",
							data: null,
						} as NetworkResponseType;
					}
					dispatch(setErrors(["Internal App Error: Contact Tech Support"]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				}
			})
			.catch((ex) => {
				if (ifErrorRedirect) {
					navigate("/500");
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				}
				dispatch(setErrors(["Internal App Error: Contact Tech Support"]));
				return {
					status: "failed",
					data: null,
				} as NetworkResponseType;
			}) as Promise<NetworkResponseType>;
	};

	const POST = async (url: string, data: Object, ifErrorRedirect: boolean = false, ignoreError: boolean = false, ignoreMessage: boolean = false): Promise<NetworkResponseType> => {
		return axios
			.post(url, data)
			.then((response) => {
				if (!ignoreMessage) {
					dispatch(setMessages(Array.isArray(response.data.message) ? response.data.message : [response.data.message]));
				}
				return {
					status: "success",
					data: response.data.data,
				} as NetworkResponseType;
			})
			.catch((ex) => {
				const statusCode = ex.response.status;

				if (ignoreError) {
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				}

				if (statusCode === 401) {
					dispatch(setErrors(["Invalid Credentials"]));
					dispatch(clearCustomer());
					if (ifErrorRedirect) {
						navigate("/");
					}
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				}
				if (statusCode === 403) {
					if (ifErrorRedirect) {
						navigate("/403");
						return {
							status: "failed",
							data: null,
						} as NetworkResponseType;
					}
					dispatch(setErrors(["Unathorized request"]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				} else if (statusCode === 404) {
					if (ifErrorRedirect) {
						navigate("/404");
						return {
							status: "failed",
							data: null,
						} as NetworkResponseType;
					}
					dispatch(setErrors(["Resource does not exist"]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				} else if (statusCode === 400) {
					dispatch(setErrors(Array.isArray(ex.response.data.message) ? ex.response.data.message : [ex.response.data.message]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				} else if (statusCode === 422) {
					dispatch(setErrors(Array.isArray(ex.response.data.message) ? ex.response.data.message : [ex.response.data.message]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				} else if (statusCode === 500) {
					if (ifErrorRedirect) {
						navigate("/500");
						return {
							status: "failed",
							data: null,
						} as NetworkResponseType;
					}
					dispatch(setErrors(["Internal App Error: Contact Tech Support"]));
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				}
			})
			.catch((ex) => {
				if (ifErrorRedirect) {
					navigate("/500");
					return {
						status: "failed",
						data: null,
					} as NetworkResponseType;
				}
				dispatch(setErrors(["Network Error: Check your network"]));
				return {
					status: "failed",
					data: null,
				} as NetworkResponseType;
			}) as Promise<NetworkResponseType>;
	};

	return {
		GET,
		POST,
	};
};
