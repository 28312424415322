import {
	Uil500px,
	UilAirplay,
	UilAndroidAlt,
	UilArrowGrowth,
	UilAt,
	UilBagAlt,
	UilBookAlt,
	UilBookOpen,
	UilBookReader,
	UilCalendarAlt,
	UilChartBar,
	UilChat,
	UilCheckSquare,
	UilCircle,
	UilClipboardAlt,
	UilClock,
	UilCompactDisc,
	UilCreateDashboard,
	UilDatabase,
	UilDiamond,
	UilDocumentLayoutLeft,
	UilEdit,
	UilEnvelope,
	UilExchange,
	UilExclamationOctagon,
	// UilExpandArrowsAlt,
	UilFile,
	UilFileGraph,
	UilFileShieldAlt,
	UilHeadphones,
	UilIcons,
	UilImages,
	UilLayerGroup,
	UilLightbulbAlt,
	UilMap,
	UilPresentation,
	UilQuestionCircle,
	UilSearch,
	UilServer,
	UilSetting,
	UilShieldPlus,
	UilShoppingCart,
	UilSquareFull,
	UilTable,
	UilTransaction,
	UilTvRetro,
	UilUsdCircle,
	UilUserSquare,
	UilUsersAlt,
	UilWallet,
	UilWifi,
	UilWindowSection,
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import React, { ReactNode, SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import { UilEllipsisV } from "@iconscout/react-unicons";
import { NavTitle } from "../styled";
import { RootStateType } from "../../../store/store";
import Category from "../../../models/Category";
import { Network, NetworkResponseType } from "../../../shared/Network";
import { PURSEFIT_API_BASE_URL } from "../../../constants/constants";

function MenuItems({ toggleCollapsed }: { toggleCollapsed: Function }) {
	const { t } = useTranslation();
	const { GET } = Network();

	function getItem(label?: any, key?: any, icon?: any, children?: any, type?: any) {
		return {
			key,
			icon,
			children,
			label,
			type,
		};
	}

	const { topMenu, isAuthenticated, categories } = useSelector((state: RootStateType) => {
		return {
			topMenu: state.Layout.topMenu,
			isAuthenticated: state.Customer.isAuthenticated,
			categories: state.App.menuList,
		};
	});

	const path = "/";
	const location = useLocation();

	const pathName = window.location.pathname;
	const pathArray = pathName ? pathName.split(path) : [];
	const mainPath = pathArray.length > 1 ? pathArray[1] : "";
	const mainPathSplit = mainPath.split("/");

	const [openKeys, setOpenKeys] = React.useState(!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`] : []);

	const onOpenChange = (keys: any) => {
		setOpenKeys(keys[keys.length - 1] !== "recharts" ? [keys.length && keys[keys.length - 1]] : keys);
	};

	const onClick = (item: any) => {
		if (item.keyPath.length === 1) setOpenKeys([]);
	};

	const darkmodeActivated = () => {
		document.body.classList.add("dark-mode");
	};

	const darkmodeDiactivated = () => {
		document.body.classList.remove("dark-mode");
	};

	// const [categories, setCategories] = useState([] as Category[]);

	// const fetchCategories = useCallback(async () => {
	// 	const response: NetworkResponseType = await GET(PURSEFIT_API_BASE_URL + "/get-categories?status=active");

	// 	if (response.status === "success") {
	// 		const categories: Category[] = response.data as Category[];
	// 		setCategories(categories);
	// 	}
	// }, []);

	const obtainIcon = useCallback((category: Category) => {
		switch (category.identifier) {
			case "airtime":
				return <UilAndroidAlt />;
			case "data":
				return <UilWifi />;
			case "tv-subscription":
				return <UilTvRetro />;
			case "electricity-bill":
				return <UilLightbulbAlt />;
			case "education":
				return <UilBookAlt />;
			case "insurance":
				return <UilShieldPlus />;
			default:
				return <UilDiamond />;
		}
	}, []);

	const [items, setItems] = useState([] as any[]);

	useEffect(() => {
		let authenticatedLinks = [
			getItem(!topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t("Features")}</NavTitle>, "features", null, null, "group"),
			getItem(
				<NavLink
					onClick={() => {
						toggleCollapsed();
					}}
					to={`/dashboard`}
				>
					{t("Dashboard")}
					{/* <span className="badge badge-primary menuItem">{}</span> */}
				</NavLink>,
				"dashboard",
				!topMenu && <UilCreateDashboard />
			),
			getItem(
				<NavLink
					onClick={() => {
						toggleCollapsed();
					}}
					to={`/fund-wallet`}
				>
					{t("Fund Your Wallet")}
					{/* <span className="badge badge-primary menuItem">{}</span> */}
				</NavLink>,
				"fund-wallet",
				!topMenu && <UilWallet />
			),
			getItem(
				<NavLink
					onClick={() => {
						toggleCollapsed();
					}}
					to={`/account`}
				>
					{t("Account")}
					{/* <span className="badge badge-primary menuItem">{}</span> */}
				</NavLink>,
				"account",
				!topMenu && <UilUserSquare />
			),
			getItem(!topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t("Quick Tools")}</NavTitle>, "quick-tools", null, null, "group"),
			getItem(
				<NavLink
					onClick={() => {
						toggleCollapsed();
					}}
					to={`/transactions`}
				>
					{t("Transactions")}
					{/* <span className="badge badge-primary menuItem">{}</span> */}
				</NavLink>,
				"transactions",
				!topMenu && <UilTransaction />
			),
			getItem(
				<NavLink
					onClick={() => {
						toggleCollapsed();
					}}
					to={`/reports`}
				>
					{t("Reports")}
					{/* <span className="badge badge-primary menuItem">{}</span> */}
				</NavLink>,
				"reports",
				!topMenu && <UilFileGraph />
			),
			getItem(
				<NavLink
					onClick={() => {
						toggleCollapsed();
					}}
					to={`/support`}
				>
					{t("Support")}
					{/* <span className="badge badge-primary menuItem">{}</span> */}
				</NavLink>,
				"support",
				!topMenu && <UilHeadphones />
			),
		];

		const otherLinks = [
			getItem(!topMenu && <NavTitle className="ninjadash-sidebar-nav-title">{t("Quick Tools")}</NavTitle>, "quick-tools", null, null, "group"),
			getItem(
				<NavLink
					onClick={() => {
						toggleCollapsed();
					}}
					to={`/support`}
				>
					{t("Support")}
					{/* <span className="badge badge-primary menuItem">{}</span> */}
				</NavLink>,
				"support",
				!topMenu && <UilHeadphones />
			),
		];

		if (categories.length > 0) {
			let links = [
				...categories.map((cat) =>
					getItem(
						<NavLink
							onClick={() => {
								toggleCollapsed();
							}}
							to={`/cat/${cat.identifier}`}
						>
							{t(`${cat.name}`)}
							{/* <span className="badge badge-primary menuItem">{}</span> */}
						</NavLink>,
						`${cat.identifier}`,
						!topMenu && obtainIcon(cat)
					)
				),
			];

			if (isAuthenticated) {
				links = [...links, ...authenticatedLinks];
			} else {
				links = [...links, ...otherLinks];
			}
			setItems((state) => links);
		} else {
			let links: any[] = [];

			if (isAuthenticated) {
				links = [...links, ...authenticatedLinks];
			} else {
				links = [...links, ...otherLinks];
			}

			setItems((state) => links);
		}
	}, [categories, isAuthenticated]);

	// useEffect(() => {
	// 	toggleCollapsed;
	// }, [location.pathname]);

	return (
		<Menu
			onOpenChange={onOpenChange}
			onClick={onClick}
			mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
			// // eslint-disable-next-line no-nested-ternary
			defaultSelectedKeys={!topMenu ? [`${mainPathSplit.length === 1 ? "home" : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]}`] : []}
			defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`] : []}
			overflowedIndicator={<UilEllipsisV />}
			openKeys={openKeys}
			items={items}
		/>
	);
}

// MenuItems.propTypes = {
//   toggleCollapsed: propTypes.func,
// };

export default MenuItems;
