import { useSelector } from "react-redux";
import { RootStateType } from "../../store/store";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notification } from "antd";

const Alert = () => {
	const { messages, errors } = useSelector((state: RootStateType) => state.App);

	const [api, contextHolder] = notification.useNotification();

	const triggerMessage = () => {
		messages.map((msg) => {
			api["success"]({
				message: "Good work!",
				description: msg,
			});
		});
	};

	const triggerError = () => {
		errors.map((error) => {
			api["error"]({
				message: "Error!",
				description: error,
			});
		});
	};

	useEffect(() => {
		if (messages.length > 0) {
			triggerMessage();
		}
	}, [messages]);

	useEffect(() => {
		if (errors.length > 0) {
			triggerError();
		}
	}, [errors]);

	return <>{contextHolder}</>;
};

export default Alert;
