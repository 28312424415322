import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import staticData from "../../config/config";

const initialLayoutState = {
	rtlData: staticData.rtl,
	topMenu: staticData.topMenu,
	mode: staticData.mainTemplate,
	loading: false,
	rtlLoading: false,
	menuLoading: false,
	mainContentLoading: false,
	error: null,
};

const LayoutReducer = createSlice({
	name: "layout-reducer",
	initialState: initialLayoutState,
	reducers: {
		CHANGE_LAYOUT_MODE_BEGIN: (state) => {
			return { ...state, loading: true };
		},
		CHANGE_LAYOUT_MODE_SUCCESS: (state, action: PayloadAction<string>) => {
			return { ...state, mode: action.payload, loading: false };
		},
		CHANGE_MENU_MODE_BEGIN: (state) => {
			return { ...state, menuLoading: true };
		},
		CHANGE_MENU_MODE_SUCCESS: (state, action: PayloadAction<boolean>) => {
			return {
				...state,
				topMenu: action.payload,
				menuLoading: false,
			};
		},
	},
});

export const { CHANGE_LAYOUT_MODE_BEGIN, CHANGE_LAYOUT_MODE_SUCCESS, CHANGE_MENU_MODE_BEGIN, CHANGE_MENU_MODE_SUCCESS } = LayoutReducer.actions;
export default LayoutReducer.reducer;
