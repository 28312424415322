import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Customer from "../../models/Customer";
import User from "../../models/User";

const userState: User = {
	id: 0,
	email: "",
	phone: "",
	first_name: "",
	last_name: "",
	created_at: new Date(),
	updated_at: new Date(),
};

const CustomerReducerState: Customer & { isAuthenticated: boolean; walletBalance: number } = {
	id: 0,
	status: "",
	user: userState,
	customerRoles: [],
	created_at: new Date(),
	updated_at: new Date(),
	isAuthenticated: false,
	walletBalance: 0,
};

const CustomerReducer = createSlice({
	name: "customer-reducer",
	initialState: CustomerReducerState,
	reducers: {
		setCustomer: (state, action: PayloadAction<Customer>) => {
			return { ...state, ...action.payload, isAuthenticated: true };
		},
		clearCustomer: (state) => {
			return { ...state, ...CustomerReducerState, isAuthenticated: false };
		},
		setCustomerWalletBalance: (state, action: PayloadAction<{ balance: number }>) => {
			return { ...state, walletBalance: action.payload.balance };
		},
	},
});

export const { setCustomer, clearCustomer, setCustomerWalletBalance } = CustomerReducer.actions;
export default CustomerReducer.reducer;
