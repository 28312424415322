import { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ErrorBoundaryInner from "./inner";

interface Props {
	children: ReactNode;
}

const ErrorBoundary = (props: Props) => {
	const [hasError, setHasError] = useState(false);
	const location = useLocation();
	useEffect(() => {
		if (hasError) {
			setHasError(false);
		}
	}, [location.key]);

	return (
		<ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
			{props.children}
		</ErrorBoundaryInner>
	);
};

export default ErrorBoundary;
