import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./reducers/app-reducer";
import layoutReducer from "./reducers/layout-reducer";
import customerReducer from "./reducers/customer-reducer";

export const ProviderStore = configureStore({
	reducer: {
		App: appReducer,
		// Admin: adminReducer,
		Layout: layoutReducer,
		Customer: customerReducer,
	},
});

export type RootStateType = ReturnType<typeof ProviderStore.getState>;
