import PropTypes from "prop-types";
import React, { ReactNode, useState } from "react";
import { ButtonStyled, ButtonStyledGroup } from "./styled";

// @Todo props spreading

type ButtonPropType = {
	type: "primary" | "secondary" | "success" | "info" | "warning" | "danger" | "link" | "dark" | "light" | "gray" | "white" | "dashed" | "error" | "extra-light" | "default";
	shape?: string;
	icon?: string;
	size?: string;
	color?: string;
	outlined?: boolean;
	transparented?: boolean;
	raised?: boolean;
	squared?: boolean;
	social?: boolean;
	load?: boolean;
	ghost?: boolean;
	children?: Object | string | ReactNode;
	[key: string]: any;
};

function Button(props: ButtonPropType = { type: "default" }) {
	const { type, shape, icon, size, outlined, ghost, transparented, raised, squared, color, social, load, children, ...rest } = props;
	const [state, setState] = useState({
		loading: false,
	});

	const enterLoading = () => {
		setState({ loading: true });
	};

	return (
		<ButtonStyled
			squared={squared}
			outlined={outlined ? 1 : 0}
			ghost={ghost}
			transparent={transparented ? 1 : 0}
			raised={raised ? 1 : 0}
			data={type}
			size={size}
			shape={shape}
			type={type}
			icon={icon}
			color={color}
			social={social}
			onClick={load && enterLoading}
			loading={state.loading}
			{...rest}
		>
			{children}
		</ButtonStyled>
	);
}

function BtnGroup({ children }: { children: ReactNode }) {
	return <ButtonStyledGroup>{children}</ButtonStyledGroup>;
}

BtnGroup.propTypes = {
	children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export { Button, BtnGroup };
