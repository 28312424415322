import { ConfigProvider, Spin } from "antd";
import "antd/dist/antd.less";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoutes from "./routes/private-routes";
import AuthenticationMiddleware from "./middlewares/authentication-middleware";
import RedirectMiddleware from "./middlewares/redirect-middleware";
import { Network, NetworkResponseType } from "./shared/Network";
import { PURSEFIT_API_BASE_URL } from "./constants/constants";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import config from "./config/config";
import { useSelector } from "react-redux";
import { RootStateType } from "./store/store";
import "./static/css/style.css";
import Customer from "./models/Customer";
import { setCustomer } from "./store/reducers/customer-reducer";
import Category from "./models/Category";
import { setMenuList, setPlatformSettings } from "./store/reducers/app-reducer";
import User from "./models/User";
import GeneralSetting from "./models/GeneralSetting";
import "./styles/App.css";
import ErrorBoundary from "./components/ErrorBoundary";

const { themeColor } = config;

function App() {
	const { rtl, topMenu, mainContent } = useSelector((state: RootStateType) => {
		return {
			rtl: state.Layout.rtlData,
			topMenu: state.Layout.topMenu,
			mainContent: state.Layout.mode,
		};
	});
	const [isInitialized, setIsInitialized] = useState(false);
	const dispatch = useDispatch();
	const { GET } = Network();
	const { isAuthenticated } = useSelector((state: RootStateType) => state.Customer);
	const [isFetchingAccount, setIsFetchingAccount] = useState(true);
	const [isFetchingMenu, setIsFetchingMenu] = useState(true);
	const [isFetchingPlatformSetting, setIsFetchingPlatformSetting] = useState(true);

	const startApp = useCallback(async () => {
		setIsFetchingAccount(true);
		const response: NetworkResponseType = await GET(PURSEFIT_API_BASE_URL + "/account", false, true);

		if (response.status === "success") {
			let res = response.data as { user: User; customer: Customer; roles: number[]; wallet_balance: number };
			let customer: Customer & { walletBalance: number } = { ...res.customer, user: res.user, customerRoles: res.roles, walletBalance: res.wallet_balance };

			dispatch(setCustomer(customer));
			setIsFetchingAccount(false);
		} else {
			setIsFetchingAccount(false);
		}
	}, []);

	const fetchCategories = useCallback(async () => {
		setIsFetchingMenu(true);
		const response: NetworkResponseType = await GET(PURSEFIT_API_BASE_URL + "/get-categories?status=active");

		if (response.status === "success") {
			const categories: Category[] = response.data as Category[];
			dispatch(setMenuList(categories));
			setIsFetchingMenu(false);
		} else {
			setIsFetchingMenu(false);
		}
	}, []);

	const fetchPlatformSetting = useCallback(async () => {
		const response: NetworkResponseType = await GET(PURSEFIT_API_BASE_URL + "/fetch-platform-setting");

		if (response.status === "success") {
			let res = response.data as GeneralSetting;

			if (res == null) {
				res = { favicon: null, logo: null, name: null, url: null, support_email: null, support_phone: null, transactional_email: null, billboard_description: null };
			}

			dispatch(setPlatformSettings(res));
			setIsFetchingPlatformSetting(false);
		} else {
			setIsFetchingPlatformSetting(false);
		}
	}, []);

	const location = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname, location.key]);

	useEffect(() => {
		fetchPlatformSetting();
		startApp();
		fetchCategories();
	}, []);

	useEffect(() => {
		if (!isFetchingAccount && !isFetchingMenu && !isFetchingPlatformSetting) {
			setIsInitialized(true);
		}
	}, [isFetchingAccount, isFetchingMenu, isFetchingPlatformSetting]);

	if (!isInitialized) {
		return (
			<ConfigProvider direction={rtl ? "rtl" : "ltr"}>
				<ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
					<ErrorBoundary>
						<div className="spin">
							<Spin />
						</div>
					</ErrorBoundary>
				</ThemeProvider>
			</ConfigProvider>
		);
	} else {
		return (
			<ConfigProvider direction={rtl ? "rtl" : "ltr"}>
				<ThemeProvider theme={{ ...themeColor, rtl, topMenu, mainContent }}>
					<ErrorBoundary>
						<Routes>
							<Route
								path="/*"
								element={
									<Suspense
										fallback={
											<div className="spin">
												<Spin />
											</div>
										}
									>
										<Routes>
											<Route path="/*" element={<AuthenticationMiddleware />}></Route>
										</Routes>
									</Suspense>
								}
							/>
							{/* <Route path="*" element={<NotFound />} /> */}
						</Routes>
					</ErrorBoundary>
				</ThemeProvider>
			</ConfigProvider>
		);
	}
}

export default App;
